<template>
  <svg
    @click="$emit('selected')"
    :class="{ star: true, active, last }"
    :style="{ animationDelay: `${animationDelay}s`, color: style.starInactiveColor }"
    aria-hidden="true"
    focusable="false"
    data-icon="star"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
    ></path>
  </svg>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Star",
    props: ["active", "animationDelay", "last"],
    computed: {
      ...mapGetters({
        style: "design/style"
      })
    },

    mounted() {
      const keyFrames = ` \
      .star.active { \
        animation: gelatine 0.6s linear; \
        animation-fill-mode: forwards; \
      } \
      .star.active.last { \
        animation: gelatine-strong 0.6s linear; \
        animation-fill-mode: forwards; \
      }\
      @keyframes gelatine { \
        from {\
        color: ${this.style.starActiveColor};\
        transform: scale(1, 1);\
      }\
      25% { \
        transform: scale(0.8, 1.2); \
      } \
      50% { \
        transform: scale(1.2, 0.8); \
      } \
      75% { \
        transform: scale(1, 1.1); \
      } \
      to { \
        color: ${this.style.starActiveColor}; \
        transform: scale(1, 1); \
      }\
    }\
    @keyframes gelatine-strong { \
      from { \
        color: ${this.style.starActiveColor}; \
        transform: scale(1, 1); \
      } \
      25% { \
        transform: scale(0.7, 1.3); \
      } \
      50% { \
        transform: scale(1.2, 0.8); \
      } \
      75% { \
        transform: scale(1.1, 1.2); \
      } \
      to { \
        color: ${this.style.starActiveColor}; \
        transform: scale(1, 1); \
      }\
    }`;
      var style = document.createElement("style");
      style.innerHTML = keyFrames;
      document.getElementsByTagName("head")[0].appendChild(style);
    }
  };
</script>

<style lang="scss" scoped>
  .star {
    color: lightgrey;
    width: 32px;
    margin: 0 6px;
  }
</style>
