<template>
  <div class="h-100 d-flex flex-column">
    <div class="question-content-wrapper">
      <div class="back-wrapper">
        <v-icon v-if="currentStep !== 0" aria-hidden="false" @click="previousQuestion" class="back-arrow ml-0">
          mdi-chevron-left
        </v-icon>
      </div>
      <p
        :class="`question-title ${currentQuestion.kind === 'category' ? 'question-title-bold' : ''}`"
        :style="{ color: style.textColor }"
        v-html="questionTitle"
      ></p>
      <div></div>
    </div>
    <div class="flex-grow-1 question-wrapper">
      <component v-bind:is="questionComponent" :question="currentQuestion" :key="currentQuestion.id"> </component>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import { capitalizeQuestion } from "@/helpers/text";
  import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
  import OpenEnded from "./open_ended/index";
  import Quantifiable from "./quantifiable/index";
  import FollowUp from "./follow_up/index";
  import Category from "./Category";

  const questionTypes = {
    multiple_choice: MultipleChoiceQuestion,
    open_ended: OpenEnded,
    category: Category,
    follow_up: FollowUp,
    quantifiable: Quantifiable,
  };

  export default {
    name: "QuestionRenderer",
    components: { Category, FollowUp },
    computed: {
      ...mapGetters({
        currentQuestion: "survey/currentQuestion",
        progress: "survey/progress",
        currentStep: "survey/currentStep",
        style: "design/style",
      }),
      questionComponent() {
        return questionTypes[this.currentQuestion.kind];
      },
      questionTitle() {
        if (this.currentQuestion.content) {
          const isStandard = this.currentQuestion.standard;
          let title = isStandard ? capitalizeQuestion(this.currentQuestion.content) : this.currentQuestion.content;
          if (!["love", "improve"].includes(this.currentQuestion.id)) return title;
          const boldWord = this.currentQuestion.id === "improve" ? "improve" : "like";
          const formattedTitle = title.replace(boldWord, "<b>" + boldWord + "</b>");
          return formattedTitle;
        }
        return "";
      },
    },
    methods: {
      ...mapMutations({
        previousQuestion: "survey/previousQuestion",
      }),
    },
    watch: {
      questionComponent() {
        return document.querySelector("#review").scroll(0, 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .question-content-wrapper {
    display: grid;
    padding: 40px 0px 20px 0px;
    grid-template-columns: 1fr 10fr 1fr;
    max-height: 160px;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #3d464d;
    .back-wrapper {
      position: relative;
      button {
        position: absolute;
      }
    }
  }
  .question-title {
    font-weight: bold;
  }
  b {
    font-weight: 700;
  }
  .question-content {
    font-size: 24px;
    line-height: 32px;
    color: #3d464d;
    margin: 40px auto 0 auto;
    font-weight: bold;
  }
  .bold-word {
    font-weight: 700;
  }
  .back-arrow {
    margin: 0;
    font-size: 32px !important;
    transform: translate(0, -70%);
    left: -15px;
    color: #3d464d !important;
  }
</style>
