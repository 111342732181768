<template>
  <div class="h-100 multiple-choice pt-5">
    <div class="flex-grow-1 d-flex align-center mb-3">
      <OptionCards
        v-if="questionIsEmployeeSelection"
        :shadowed="false"
        :options="cardOptions"
        v-on:optionSelected="handleSelect"
        :withImage="true"
        :large="true"
      />
      <v-row class="d-flex flex-column w-100 align-center mb-3" v-else>
        <div
          v-for="(opt, index) in questionIsDeliveryPlatform ? reorderOptions : cardOptions"
          :key="index"
          class="my-2 d-flex align-center justify-center option-box"
          :class="{ delivery: questionIsDeliveryPlatform }"
          @click="handleSelect(opt)"
          :style="{
            ...style.optionBox,
            ...(option === opt ? style.selectedBorder : style.standardBorder),
            ...style.borderRadius,
          }"
        >
          <p
            v-if="questionIsDeliveryPlatform && opt.content === 'Else'"
            class="option-text"
            :style="{ color: style.textColor }"
          >
            {{ $t("Somewhere else") }}
          </p>
          <img
            v-else-if="questionIsEmployeeSelection || questionIsDeliveryPlatform"
            :src="generateImgPath(opt)"
            class="delivery-image"
          />
          <p
            v-if="!questionIsDeliveryPlatform"
            class="option-text"
            :style="{ ...(style.optionText || { color: style.textColor }) }"
          >
            {{ opt.content }}
          </p>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import OptionCards from "@/components/review_wizard/OptionCards";
  export default {
    name: "MultipleChoiceQuestion",
    props: {
      question: {
        type: Object,
        default: null,
      },
    },
    components: {
      OptionCards,
    },
    data() {
      return {
        option: undefined,
        mounted: false,
      };
    },
    methods: {
      ...mapMutations({
        nextQuestion: "survey/nextQuestion",
        setCurrentStep: "survey/setCurrentStep",
      }),
      buildUrl(option) {
        return option.image;
      },
      submit() {
        this.$store.commit("survey/setAnswer", {
          questionName: this.question.question_name,
          answer: { content: this.option.id, question_id: this.question.id, image: this.option.image },
        });
        this.nextQuestion();
        this.setCurrentStep();
      },
      handleSelect(option) {
        this.option = option;
        setTimeout(() => {
          this.submit();
        }, 400);
      },
      generateImgPath(opt) {
        try {
          const name = opt.label.replace(/ /g, "_").replace(/\./g, "_");
          return require(`@/assets/logo/${name.toLowerCase()}.png`);
        } catch (e) {
          return opt.image;
        }
      },
    },
    computed: {
      ...mapGetters({
        optionsFor: "survey/optionsFor",
        style: "design/style",
      }),
      answerOptions() {
        return this.optionsFor(this.question);
      },
      cardOptions() {
        return this.answerOptions.map((opt) => ({
          id: opt.id,
          content: opt.content,
          label: opt.label,
          image: this.buildUrl(opt) || this.placeHolderImage,
          text: opt.content,
        }));
      },
      reorderOptions() {
        const options = [...this.cardOptions];
        const takeAwayIndex = options.findIndex((o) => o.label === "take_away");
        if (takeAwayIndex < 0) return options;
        const takeAway = options.splice(takeAwayIndex, 1);
        return [...takeAway, ...options];
      },

      placeHolderImage() {
        return this.questionIsEmployeeSelection ? "person_blank.jpg" : "blank.png";
      },
      questionIsEmployeeSelection() {
        return this.question.question_name === "employee";
      },
      questionIsDeliveryPlatform() {
        return this.question.question_name === "delivery_platform";
      },
    },
  };
</script>

<style lang="scss">
  .multiple-choice {
    .option-card {
      min-height: 35vh;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    .v-responsive.v-image {
      margin: auto;
    }
    .venue-text {
      height: 80px;
    }
    .option-box {
      width: 100%;
      height: 52px;
      .option-text {
        font-size: 14px;
        margin: 0;
        font-weight: bold;
        color: #363636;
      }

      &.delivery {
        height: 85px;
        .option-text {
          font-size: 24px;
        }
      }
    }
    .delivery-image {
      width: 100%;
      max-width: 160px;
      max-height: 55px;
      object-fit: contain;
    }

    @media only screen and (max-width: 600px) {
      .image-card {
        width: 95%;
        margin: auto auto;
      }
    }
  }
</style>
