<template>
  <div class="h-100 d-flex flex-column">
    <div class="d-flex flex-column align-center my-5">
      <EmojiRating v-if="style.showEmoji" :rating="answer.rating" />
      <div class="follow-up-stars mt-3">
        <Stars :rating="answer.rating" />
      </div>
    </div>
    <v-form ref="form" class="h-50 textarea-form-wrapper ">
      <TextArea
        :content="answer.content || ''"
        :placeholder="$t(`followUpPlaceholder${this.question.id === 'love' ? 'Love' : 'Improve'}`)"
        @change="updateAnswer"
        :error="!valid && !hideError"
      />
    </v-form>
    <Button :fixed="keyboardOpen" class="mt-5 next-btn" :class="{ disabled: !canGoNext }" @click="handleNext">
      {{ $t("Next") }}
      <v-icon aria-hidden="false" class="next-arrow" :color="style.buttonColor">
        mdi-chevron-right
      </v-icon>
    </Button>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import EmojiRating from "@/components/review_wizard/shared/EmojiRating";
  import Button from "@/components/global/Button";
  import Stars from "@/components/review_wizard/quantifiable/Stars";
  import TextArea from "@/components/review_wizard/open_ended/TextArea";

  export default {
    name: "ContentFollowUp",
    props: ["question"],
    components: { EmojiRating, Button, Stars, TextArea },
    data() {
      return {
        valid: true,
        keyboardOpen: false,
        text: "",
        hideError: false
      };
    },
    computed: {
      ...mapGetters({
        answerFor: "survey/answerFor",
        style: "design/style",
        isDemo: "isDemo"
      }),
      answer() {
        return this.answerFor(this.question);
      },
      canGoNext() {
        return this.isDemo ? true : this.text && this.text.length > 10;
      }
    },
    methods: {
      ...mapMutations({
        setAnswer: "survey/setAnswer",
        nextQuestion: "survey/nextQuestion",
        setCurrentStep: "survey/setCurrentStep"
      }),
      updateAnswer(text) {
        this.hideError = false;
        this.text = text;
        this.setAnswer({ answer: { ...this.answer, content: text }, questionName: this.question.question_name });
        this.valid = text.length > 10;
      },
      handleNext() {
        this.hideError = false;
        this.valid = false;
        if (!this.canGoNext) {
          this.valid = false;
        } else {
          this.nextQuestion();
          this.setCurrentStep();
        }
      }
    },
    mounted() {
      this.valid = this.answer.content && this.answer.content.length >= 10;
      this.text = this.answer.content;
      this.hideError = !this.answer.content || !this.answer.content.length >= 10;
    }
  };
</script>

<style lang="scss">
  .textarea-form-wrapper {
    .v-textarea .v-input__control {
      box-shadow: 0px 20px 40px -5px rgb(200 209 226 / 15%) !important;
      border-radius: 8px;
      font-size: 14px;
      line-height: 19px;
      color: #3d464d;
      opacity: 0.8;
      background: #fff;
    }
    .v-textarea .v-input__slot {
      box-shadow: none !important;
    }
  }
  .follow-up-stars {
    width: 100%;
    padding: 0 60px;
  }
</style>
