<template>
  <div class="reward-wrapper" :class="{ submit: submitPage }">
    <img
      v-if="submitPage"
      class="reward-gift-img submit"
      :src="require(`@/assets/giphys/gift/${giftImg}.png`)"
      alt="gift"
    />
    <div class="reward-box" :style="{ ...style.rewardBox }">
      <h3 class="gift-title" :style="{ ...style.giftTitle }">{{ $t("Your gift") }}:</h3>
      <h2 class="gift-name" :style="{ ...style.giftName }">{{ reward }}</h2>
    </div>
    <img v-if="!submitPage" class="reward-gift-img" :src="require(`@/assets/giphys/gift/${giftImg}.png`)" alt="gift" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "RewardBox",
    props: {
      height: {
        type: Number,
        default: 55
      }
    },
    computed: {
      ...mapGetters({
        reward: "reward/name",
        style: "design/style"
      }),

      submitPage() {
        return this.$route.name === "submit";
      },
      giftImg() {
        return this.style.giftIcon || "gift_new";
      },
      fontModifier() {
        if (this.reward.length > 70) {
          return 0.25;
        } else if (this.reward.length > 50) {
          return 0.15;
        }
        return 0;
      }
    }
  };
</script>

<style lang="scss">
  @import "~assets/stylesheets/mixins/text-animations.scss";
  .reward-wrapper {
    .reward-box {
      background: #fefeff;
      box-shadow: 0px 20px 40px -5px rgba(200, 209, 226, 0.15);
      border-radius: 8px;
      padding: 26px 12px;
      text-align: center;
      margin-bottom: 32px;
      .gift-title {
        font-weight: bold;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
      }
      .gift-name {
        font-size: 18px;
      }
    }
    .reward-gift-img {
      width: 190px;
      height: 190px;
      object-fit: cover;
      display: block;
      margin: auto;
      object-fit: contain;
    }
    &.submit {
      margin-bottom: 32px;
      .reward-box {
        background: none;
        box-shadow: none;
      }
      .reward-gift-img {
        height: 80px;
        object-fit: cover;
        display: block;
        margin: 32px auto 16px auto;
        object-fit: contain;
      }
    }
  }
</style>
