<template>
  <div class="area-wrapper">
    <textarea
      ref="textArea"
      v-model="text"
      :style="{ ...style.textArea, 'font-size': '14px' }"
      :placeholder="this.placeholder"
    ></textarea>
    <div class="red--text error-box">
      <small> {{ error ? $t("Please write at least 10 characters") : "" }}</small>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "TextArea",
    props: ["content", "placeholder", "error"],
    computed: {
      ...mapGetters({
        style: "design/style"
      }),

      inputRows() {
        return this.$device.isMobile ? "8" : "10";
      },
      text: {
        get: function() {
          return this.content;
        },
        set: function(newText) {
          this.$emit("change", newText);
        }
      }
    },
    mounted() {
      window.scroll(0, 0);
    }
  };
</script>

<style lang="scss" scoped>
  .area-wrapper {
    margin-bottom: 60px;
    textarea {
      width: 100%;
      max-height: 200px;
      min-height: 200px;
      font-size: 16px;
      padding: 8px;
      &:focus-visible {
        outline: none;
      }
    }
    .error-box {
      height: 30px;
    }
  }
</style>
