<template>
  <div class="loader loader--style1" title="0" :style="{ height, 'background-color': backgroundColor }">
    <svg
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="width"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xml:space="preserve"
    >
      <path
        :fill="color"
        opacity="0.2"
        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
      />
      <path
        :fill="color"
        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      width: {
        type: String,
        default: "20%"
      },
      height: {
        type: String,
        default: "100px"
      },
      color: {
        type: String,
        default: "#cdcdcd"
      },
      backgroundColor: {
        type: String,
        default: "white"
      }
    },
    computed: {
      ...mapGetters({
        style: "design/style"
      })
    }
  };
</script>

<style scoped>
  .loader {
    margin: 0 0 2em;
    width: 100%;
    text-align: center;
    margin: 0;
    display: inline-block;
    vertical-align: top;
  }
</style>
