<template>
  <div class="d-flex justify-space-between w-100 stars-container">
    <Star
      v-for="option in options"
      :key="option"
      :active="option <= rating"
      :animationDelay="(option - rating) / 15"
      :last="option == rating"
      @selected="$emit('selected', option)"
    />
  </div>
</template>

<script>
  import Star from "@/components/review_wizard/quantifiable/Star";

  export default {
    name: "Stars",
    components: { Star },
    props: ["rating"],
    data() {
      return {
        options: [1, 2, 3, 4, 5]
      };
    }
  };
</script>

<style scoped>
  .stars-container {
    height: 50px;
  }
</style>
