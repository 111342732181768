<template>
  <div ref="page" id="review" :style="{ ...style.backgroundColor }">
    <div class="loader" v-if="loading">
      <Loader width="140px" />
    </div>
    <div v-else-if="languageLoaded">
      <template v-if="!reviewStarted && !loading">
        <StartReview :gift="venue.gift" v-on:reviewStarted="handleStart" />
      </template>
      <template v-else-if="reviewStarted && !loading">
        <template>
          <QuestionRenderer />
        </template>
      </template>
      <div
        ref="stepper"
        id="stepper"
        :style="{
          ...style.backgroundColor,
          width: $refs.page && $refs.page.offsetWidth + 'px',
          left: `calc(50% - ${$refs.page && $refs.page.offsetWidth / 2}px )`,
        }"
      >
        <v-progress-linear v-model="progress" :color="style.baseColor" height="6"></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
  // import Botd from "@fingerprintjs/botd";
  import { mapMutations, mapGetters } from "vuex";
  import StartReview from "@/components/review_wizard/StartReview.vue";
  import QuestionRenderer from "@/components/review_wizard/QuestionRenderer.vue";
  import Loader from "@/components/global/Loader";

  export default {
    components: {
      StartReview,
      QuestionRenderer,
      Loader,
    },
    name: "IndexPage",

    data() {
      return {
        // Once review has started, display QuestionRenderer component.
        // This component will loop through each question for this venue.
        // When all question have been submitted it will emit questionsFinished.
        reviewStarted: false,
        sessionReady: true,
        questionsFinished: false,
        overlay: true,
        error: false,
        loading: true,
        lastQuestion: undefined,
        originalHeight: 0,
        footer: undefined,
      };
    },
    methods: {
      ...mapMutations({
        setCurrentStep: "survey/setCurrentStep",
        setInitialQuestions: "survey/setInitialQuestions",
      }),
      setToken() {
        const token = this.$route.query.token || this.$route.params.token;
        if (!token) {
          this.loading = false;
          this.error = "Please supply a valid token or QR code.";
        } else {
          localStorage.setItem("reviewToken", token);
          this.$store.commit("venue/setState", { key: "token", data: token });
        }
      },
      setRequestType() {
        // The token is used for a Survey used for marketing
        if (this.$route.query.demo === "true" && this.$route.query.token !== "aUSWY3waVpkCeAYGo56DBho2") {
          this.$store.commit("setState", { key: "isDemo", data: true });
        }
      },
      handleStart() {
        this.reviewStarted = true;
        localStorage.clear();
        localStorage.setItem("query", JSON.stringify(this.$route.query));
        const payload = {
          current_step: "category",
          progress: 1,
        };
        this.$store.dispatch("completion/update", payload);
        this.setCurrentStep();
      },
      hideFooter() {
        if (!this.$device.isMobile) return;
        if (this.$refs.page) this.$refs.page.style.minHeight = "100vh";
        this.footer.classList.add("d-none");
        if (this.$refs.stepper) this.$refs.stepper.classList.add("d-none");
      },
      showFooter() {
        if (!this.$device.isMobile) return;
        this.footer.classList.remove("d-none");
        if (this.$refs.stepper) this.$refs.stepper.classList.remove("d-none");
      },
      toggleFooter(e) {
        if (window.innerHeight < this.originalHeight) {
          this.hideFooter();
        } else {
          this.showFooter();
        }
      },
    },
    computed: {
      ...mapGetters({
        venue: "venue/show",
        verifyCustomer: "venue/verifyCustomer",
        token: "venue/token",
        currentQuestion: "survey/currentQuestion",
        getNextQuestions: "survey/getNextQuestions",
        currentStep: "survey/currentStep",
        initialQuestions: "survey/initialQuestions",
        style: "design/style",
        languageLoaded: "language/languageLoaded",
      }),
      progress() {
        if (!this.reviewStarted) return 0;
        return ((this.currentStep + 1) / this.initialQuestions) * 100;
      },
    },

    watch: {
      style(val) {
        document.querySelector(".v-application").setAttribute("style", `background-color:${val.background} !important`);
      },
      async currentQuestion(question) {
        try {
          if (!this.reviewStarted) return;
          if (this.lastQuestion === question.id) return;
          this.lastQuestion = question.id;
          const payload = {
            current_step: question.completion_name || question.question_name,
            progress: this.currentStep,
          };
          await this.$store.dispatch("completion/update", payload);
          this.lastQuestion = undefined;
        } catch {
          return false;
        }
      },
      immediate: true,
      deep: true,
    },
    head() {
      return {
        meta: [
          {
            hid: "theme-color",
            name: "theme-color",
            content: this.style.baseColor,
          },
        ],
      };
    },
    async mounted() {
      this.setToken();
      this.setRequestType();
      if (localStorage.getItem("refresh") === "1") {
        localStorage.removeItem("refresh");
        this.$router.go();
      }
      localStorage.removeItem("reviewCompleted");
      if (this.token) {
        await this.$store.dispatch("venue/fetch");
        this.overlay = false;
        this.loading = false;
      }
      if (this.verifyCustomer) {
        // const botdPromise = Botd.load({ publicKey: "bdA2Vq0wNyYnUwwNzU8aLxzJ" });
        // const botd = await botdPromise;
        // const result = await botd.detect();
        // const requestId = result.requestId;
        // const response = await this.$axios.get(`/request/verify?request_id=${requestId}`);
      }
      this.$store.dispatch("review/resetAnswers");
      this.footer = document.querySelector("#footer");
      this.footer.style.width = this.$refs.page && this.$refs.page.offsetWidth + "px";
      this.footer.style.left = `calc(50% - ${this.$refs.page && this.$refs.page.offsetWidth / 2}px )`;
      this.originalHeight = window.innerHeight;
      window.addEventListener("resize", this.toggleFooter);
    },

    unmounted() {
      window.removeEventListener("resize", this.toggleFooter);
    },

    updated() {
      if (this.initialQuestions === 0) this.setInitialQuestions(this.getNextQuestions.length + 1);
    },
  };
</script>

<style lang="scss">
  #review {
    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: fixed;
      left: 0;
      background: white;
    }
    min-height: calc(100vh - 66px);
    background-position: center;
    background-size: cover;
    padding: 0 24px 144px 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  #stepper {
    margin: auto;
    position: fixed;
    bottom: 60px;
    width: 100%;
    left: 0;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
