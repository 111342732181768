<template>
  <div class="grid w-100">
    <div :class="`grid-item`" v-for="(option, i) in options" :key="i">
      <div @click="select(option, i)" class="select-box clickable h-100">
        <div class="text-center pb-3 h-100 w-100 d-flex align-center">
          <div class="w-100 text-center">
            <div :class="imageClass" v-if="withImage">
              <div
                class="option-image"
                :style="{
                  ...(selected === i ? style.selectedBorder : ''),
                  ...(option.text === 'Someone else' ? style.standardBorder : ''),
                  ...style.borderRadius
                }"
              >
                <div
                  v-if="option.text === 'Someone else'"
                  class="else-option"
                  :style="{ ...style.lowOpacityBackground, ...style.borderRadius }"
                >
                  <QuestionMark :color="style.baseColor" />
                </div>
                <div v-else class="image-box">
                  <Loader
                    v-if="!loadedImg.includes(i)"
                    class="photo-loader"
                    height="80px"
                    width="80px"
                    :color="style.baseColor"
                    :background-color="style.loaderBackground || style.background"
                  />
                  <img
                    :src="option.image"
                    class="someone-else"
                    @load="imageLoaded(i)"
                    :style="{ ...style.borderRadius }"
                  />
                </div>
              </div>
            </div>
            <p v-html="option.text" class="subject-text mb-0 mt-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from "@/components/global/Loader.vue";
  import QuestionMark from "@/components/review_wizard/multiple_choice/QuestionMark.vue";
  import { mapGetters } from "vuex";

  export default {
    components: {
      QuestionMark,
      Loader
    },
    props: {
      options: {
        type: Array
      },
      shadowed: {
        type: Boolean
      },
      withImage: {
        type: Boolean,
        default: true
      },
      large: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        selected: undefined,
        loadedImg: []
      };
    },
    computed: {
      ...mapGetters({
        style: "design/style"
      }),
      imageClass() {
        const classes = [];
        if (this.shadowed) {
          classes.push("shadow-button");
        }
        classes.push(this.large ? "large-icon" : "subject-icon");
        return classes.join(" ");
      }
    },
    methods: {
      select(option, i) {
        this.selected = i;
        this.$emit("optionSelected", option);
      },
      imageLoaded(i) {
        this.loadedImg = [...this.loadedImg, i];
      }
    }
  };
</script>

<style lang="scss">
  .shadow-button {
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.2);
  }
  .select-box {
    height: 100%;
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .grid {
    display: grid;
    grid-column-gap: 6px;
    grid-template-columns: 1fr 1fr;
    padding: 2px;
    min-height: 80%;
  }
  .large-icon {
    max-width: 200px;
    height: 160px;
    margin: 0 auto;
    .option-image {
      height: 160px;
      padding: 4px;
      .image-box {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .photo-loader {
          height: 100px !important;
          width: 100% !important;
          position: absolute !important;
        }
        img {
          width: 100%;
          height: 148px;
          object-fit: cover;
        }
      }
      .else-option {
        width: 92%;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 80px;
          object-fit: contain;
        }
      }
    }
  }
  .option-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subject-icon {
    width: 50%;
    height: 100px;
    width: 100px;
    padding: 10px;
    display: inline-block;
    .option-image {
      border-radius: 50%;
    }
  }
  .subject-text {
    font-weight: bold;
    font-size: 1rem;
    color: #3d464d;
    text-align: center;
    line-height: 22px;
  }
</style>
