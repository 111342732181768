<template>
  <button
    type="button"
    @click="$emit('click')"
    :style="{ ...style.button, width }"
    :class="{ center, small, white, fixed }"
  >
    <slot v-if="!loader"></slot>
    <div v-else>
      <Loader width="40px" height="40px" :color="style.buttonColor" :background-color="style.button.background" />
    </div>
  </button>
</template>

<script>
  import { mapGetters } from "vuex";
  import Loader from "@/components/global/Loader.vue";

  export default {
    components: {
      Loader
    },
    props: {
      width: {
        type: String,
        defaut: () => "100%"
      },
      small: Boolean,
      center: Boolean,
      fixed: Boolean,
      loader: {
        type: Boolean,
        default: false
      },
      white: Boolean
    },
    computed: {
      ...mapGetters({
        style: "design/style"
      })
    }
  };
</script>

<style lang="scss" scoped>
  button {
    background: linear-gradient(94deg, #47bcf3 1.57%, #47d4f3 98.65%);
    color: white;
    border: 2px solid linear-gradient(94deg, #47bcf3 1.57%, #47d4f3 98.65%);
    max-width: 400px;
    border-radius: 12px;
    display: block;
    width: 100%;
    padding: 16px 0px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.disabled {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
    &.center {
      display: block;
      margin: 0 auto;
    }
    &.small {
      padding: 10px;
      height: 40px;
    }
    &.white {
      background: white;
      border: 1px solid #3d464d !important;
      color: #3d464d;
    }
    &.fixed {
      position: fixed;
      margin: auto;
      display: block;
      left: 0;
      right: 0;
    }
  }
</style>
