<template>
  <div class="h-100" v-if="question.built">
    <Content :question="question" v-if="question.attribute === 'content'" />
    <Rating :question="question" v-else />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import Content from "@/components/review_wizard/follow_up/Content";
  import Rating from "@/components/review_wizard/follow_up/Rating";

  export default {
    name: "FollowUpQuestion",
    props: ["question"],
    components: { Content, Rating },
    methods: {
      ...mapActions({
        buildFollowUpQuestion: "survey/buildFollowUpQuestion"
      }),
      ...mapMutations({
        nextQuestion: "survey/nextQuestion"
      }),
      async buildQuestion() {
        await this.buildFollowUpQuestion(this.question);
        if (this.question.skip) {
          this.nextQuestion();
        }
      }
    },
    created() {
      this.buildQuestion();
    },
    watch: {
      "question.question_name": function() {
        this.buildQuestion();
      }
    }
  };
</script>
