<template>
  <div class="d-flex flex-column justify-space-evenly pt-0 pb-5 mh-100 categories-box">
    <div class="category-question-wrapper mb-3" v-for="category in orderedCategories" :key="category.question_name">
      <p class="category-question-title" :style="{ color: style.color }">
        {{ category.content }}
      </p>
      <StarRating @selected="ratingSelected" :question="category" />
    </div>
    <div class="mt-5">
      <Button :disabled="!allCategoriesAnswered" @click="handleNext">
        {{ $t("Next") }}
        <v-icon aria-hidden="false" class="next-arrow" :color="style.buttonColor"> mdi-chevron-right </v-icon>
      </Button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import StarRating from "@/components/review_wizard/quantifiable/StarRating";
  import Button from "@/components/global/Button.vue";

  export default {
    name: "Category",
    components: {
      StarRating,
      Button,
    },
    computed: {
      ...mapGetters({
        categories: "survey/categories",
        allCategoriesAnswered: "survey/allCategoriesAnswered",
        style: "design/style",
        language: "survey/language",
      }),
      orderedCategories() {
        const allCategories = [...this.categories];
        const overallindex = allCategories.indexOf(allCategories.find((c) => c.question_name === "overall"));
        allCategories.splice(0, 0, allCategories.splice(overallindex, 1)[0]);
        return allCategories;
      },
    },
    methods: {
      ...mapMutations({
        nextQuestion: "survey/nextQuestion",
        setCurrentStep: "survey/setCurrentStep",
      }),

      ...mapActions({
        updateFollowUpCategories: "survey/updateFollowUpCategories",
      }),
      ratingSelected() {
        this.updateFollowUpCategories();
      },
      handleNext() {
        this.nextQuestion();
        this.setCurrentStep();
      },
    },
    mounted() {
      return document.querySelector("#review").scroll(0, 0);
    },
  };
</script>

<style lang="scss">
  .categories-box {
    min-height: calc(100vh - 300px);
    .category-question-wrapper {
      .category-question-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #3d464d;
        margin-bottom: 4px;
      }
    }
    .justify-space-evenly {
      justify-content: space-evenly;
    }
  }
</style>
