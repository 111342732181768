<template>
  <div id="start-review">
    <RewardBox />
    <Button center class="mt-10 mb-8 mx-auto" v-on:click="startReview">
      {{ $t("Start one minute survey") }}
    </Button>
    <p class="conditions-title">{{ $t("Terms and conditions") }}</p>
    <ul class="conditions">
      <li v-for="(condition, i) in conditions" :key="i">{{ condition }}</li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import RewardBox from "./RewardBox";
  import Button from "@/components/global/Button";

  export default {
    name: "StartReview",
    components: {
      RewardBox,
      Button
    },
    props: {
      gift: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters({
        conditions: "reward/conditions",
        startDate: "reward/startDate",
        endDate: "reward/endDate",
        name: "reward/name"
      })
    },
    methods: {
      startReview() {
        this.$emit("reviewStarted");
      }
    }
  };
</script>

<style lang="scss">
  #start-review {
    padding-top: 20px;
  }
  .conditions-title {
    text-align: center;
    font-weight: bold;
    color: #3d464d;
  }
  .conditions {
    list-style: none;
    text-align: center;
    padding: 0 !important;
  }
</style>
