<template>
  <img :src="ratingBasedEmojiImg" :height="height" :key="rating" :alt="`rating-${rating}`" class="animated-emoji" />
</template>

<script>
  import { ratingToEmojiImg } from "~/constants/colour_and_gif_mappings.js";

  export default {
    name: "EmojiRating",
    props: {
      rating: {
        type: Number
      },
      height: {
        type: Number,
        default: 50
      }
    },
    computed: {
      ratingBasedEmojiImg() {
        return ratingToEmojiImg[this.rating];
      }
    }
  };
</script>

<style scope lang="scss">
  .animated-emoji {
    animation: bounce-grow 1s;
    width: 42px;
    height: 42px;
  }

  @keyframes bounce-grow {
    from,
    to {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.05);
    }
  }
</style>
