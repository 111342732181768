<template>
  <div>
    <div class="h-100 d-flex align-center flex-column">
      <StarRating :question="question" @selected="handleSelect" />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  import StarRating from "@/components/review_wizard/quantifiable/StarRating";

  export default {
    name: "Quantifiable",
    props: ["question"],
    components: { StarRating },
    data() {
      return {
        selected: false,
      };
    },
    methods: {
      ...mapMutations({
        nextQuestion: "survey/nextQuestion",
        setAnswer: "survey/setAnswer",
      }),
      handleSelect() {
        if (this.selected) {
          return;
        }
        this.selected = true;
        // let animation play
        setTimeout(() => {
          this.nextQuestion();
        }, 500);
      },
    },
  };
</script>
