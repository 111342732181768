<template>
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.8672 53.2578C32.4 53.2578 31.2109 52.0687 31.2109 50.6016C31.2109 49.1344 32.4 47.9453 33.8672 47.9453C35.3344 47.9453 36.5234 49.1344 36.5234 50.6016C36.5234 52.0687 35.3344 53.2578 33.8672 53.2578ZM36.5234 41.3047V37.7379C41.6595 36.4954 45.218 31.8356 45.0151 26.5661C44.7946 20.8282 40.0871 16.1627 34.2978 15.9458C31.2047 15.8286 28.2839 16.9367 26.0759 19.0633C23.9057 21.1535 22.7109 23.9581 22.7109 26.9609C22.7109 28.4281 23.9 29.6172 25.3672 29.6172C26.8344 29.6172 28.0234 28.4281 28.0234 26.9609C28.0234 25.4149 28.6403 23.969 29.7609 22.8899C30.9199 21.774 32.4592 21.1935 34.0986 21.2542C37.1289 21.3683 39.5921 23.7911 39.7068 26.7705C39.8131 29.5471 37.8967 32.0005 35.1486 32.6034C32.8301 33.1123 31.2109 35.1159 31.2109 37.4765V41.3047C31.2109 42.7719 32.4 43.9609 33.8672 43.9609C35.3344 43.9609 36.5234 42.7719 36.5234 41.3047ZM51.5925 63.0999C52.8474 62.3399 53.2485 60.7067 52.4884 59.4523C51.7279 58.1973 50.0947 57.7963 48.8402 58.5563C44.3786 61.2587 39.2466 62.6875 34 62.6875C18.1818 62.6875 5.3125 49.8182 5.3125 34C5.3125 18.1818 18.1818 5.3125 34 5.3125C49.8182 5.3125 62.6875 18.1818 62.6875 34C62.6875 39.6565 61.0056 45.162 57.8232 49.9225C57.0077 51.1416 57.3356 52.7914 58.5547 53.607C59.7744 54.4225 61.4242 54.0946 62.2393 52.8749C66.0083 47.2382 68 40.7112 68 34C68 24.9184 64.4633 16.38 58.0417 9.95834C51.62 3.53665 43.0816 0 34 0C24.9184 0 16.38 3.53665 9.95834 9.95834C3.53665 16.38 0 24.9184 0 34C0 43.0816 3.53665 51.62 9.95834 58.0417C16.38 64.4633 24.9184 68 34 68C40.2173 68 46.3007 66.3056 51.5925 63.0999Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: ["color"]
  };
</script>
