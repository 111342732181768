<template>
  <div>
    <div class="h-100 d-flex align-center mb-8">
      <StarRating :question="question" @selected="handleSelect" />
    </div>
    <Button v-if="displayNext" @click="nextQuestion">
      {{ $t("Next") }}
      <v-icon aria-hidden="false" class="next-arrow" :color="style.buttonColor">
        mdi-chevron-right
      </v-icon>
    </Button>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  import StarRating from "@/components/review_wizard/quantifiable/StarRating";
  import Button from "@/components/global/Button";

  export default {
    name: "RatingFollowUp",
    props: ["question"],
    components: { StarRating, Button },
    data() {
      return {
        selected: false,
        displayNext: false,
        mounted: false
      };
    },
    methods: {
      ...mapMutations({
        nextQuestion: "survey/nextQuestion",
        setAnswer: "survey/setAnswer",
        style: "design/style"
      }),
      handleSelect(rating) {
        if (this.selected) {
          return;
        }

        this.selected = true;
        // let animation play
        setTimeout(() => {
          this.nextQuestion();
        }, 600);
      }
    },
    watch: {
      selected: {
        immediate: true,
        handler(val) {
          if (this.mounted) return;
          this.displayNext = val;
          this.mounted = true;
        }
      }
    }
  };
</script>
