<template>
  <div :class="{ 'employee-rating': questionIsEmployeeSelection }">
    <div
      v-if="questionIsEmployeeSelection"
      :style="{ ...(elseEmployee ? style.lowOpacityBackground : {}) }"
      class="image-wrapper"
      :class="{ else: elseEmployee }"
    >
      <QuestionMark v-if="elseEmployee" :color="style.baseColor" />
      <img
        v-else
        :src="getEmployeeImage"
        class="employee-image"
        :class="{ small: elseEmployee }"
        :style="{ ...style.borderRadius }"
      />
    </div>
    <div :class="`rating-row ${questionIsEmployeeSelection ? 'employee-rating-wrapper' : ''}`">
      <div class="stars">
        <Stars :rating="selectedRating" @selected="handleSelect" />
      </div>
      <div class="reaction" v-if="style.showEmoji">
        <EmojiRating v-if="selectedRating && selectedRating !== 0" :rating="selectedRating" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import QuestionMark from "@/components/review_wizard/multiple_choice/QuestionMark.vue";
  import Stars from "@/components/review_wizard/quantifiable/Stars";
  import EmojiRating from "@/components/review_wizard/shared/EmojiRating";

  export default {
    name: "StarRating",
    components: { Stars, EmojiRating, QuestionMark },
    props: {
      question: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      ...mapGetters({
        answerFor: "survey/answerFor",
        answers: "survey/answers",
        style: "design/style"
      }),
      answer() {
        return this.answerFor(this.question);
      },
      selectedRating() {
        return this.answer ? this.answer.rating : 0;
      },
      questionIsEmployeeSelection() {
        return this.question.question_name === "employee";
      },
      getEmployeeImage() {
        return this.answers.employee.image;
      },
      elseEmployee() {
        return !this.answers.employee.content;
      }
    },
    methods: {
      ...mapMutations({
        setAnswer: "survey/setAnswer"
      }),
      handleSelect(rating) {
        this.setAnswer({
          questionName: this.question.question_name,
          answer: { ...this.answer, rating, question_id: this.question.id }
        });
        this.$emit("selected");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .employee-rating {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    .image-wrapper {
      width: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
        width: 70%;
        height: 148px;
        margin: 24px 0 40px 0;
      }
      &.else {
        width: 70%;
        margin: auto;
        margin-bottom: 40px;
        border-radius: 8px;
        height: 148px;
        img {
          width: 60px !important;
          object-fit: contain;
        }
      }
    }
    .employee-rating-wrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      .reaction {
        flex: 0;
        margin: 0;
        img {
          margin: 24px 0;
        }
      }
    }
  }
  .rating-row {
    display: flex;
    align-items: center;
    min-height: 60px;
    .stars {
      display: flex;
      flex: 1;
    }
    .reaction {
      flex: 0 0 50px;
      margin-left: 30px;
    }
  }
</style>
