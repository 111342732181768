export const subjectColourClassMapping = {
  "basicDesign":      'colour-class-basic-design',
  "Food & Drink":     'colour-class-food-drink',
  "Service":          'colour-class-service',
  "Atmosphere":       'colour-class-atmopshere',
  "love_Nothing":     'colour-class-love-nothing',
  "improve_Nothing":  'colour-class-improve-nothing'
}

export const futColourClassMapping = {
  "basicDesign":  'colour-class-basic-design',
  "Google":       'colour-class-google',
  "Facebook":     'colour-class-facebook',
  "TripAdvisor":  'colour-class-tripadvisor',
  "Other":        'colour-class-other',
}

export const ratingToEmojiImg = {
  1: require("~/assets/main_icons/rating/1.png"),
  2: require("~/assets/main_icons/rating/2.png"),
  3: require("~/assets/main_icons/rating/3.png"),
  4: require("~/assets/main_icons/rating/4.png"),
  5: require("~/assets/main_icons/rating/5.png"),
}

export const ratingToStarGifMapping = {
  1: require("~/assets/giphys/rating/star1.gif"),
  2: require("~/assets/giphys/rating/star2.gif"),
  3: require("~/assets/giphys/rating/star3.gif"),
  4: require("~/assets/giphys/rating/star4.gif"),
  5: require("~/assets/giphys/rating/star5.gif"),
}
